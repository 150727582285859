  <template>
    <div id="section_qp" class="section_qp">
      <Navigator />
      <div class="bloqueqp">
          <h1 class="title_qp">Quality <span>Policy</span></h1>
          <p>In Davis Systems SA de CV we provide design, manufacture, integration, and specialized technical
  support for automation projects, ensuring the quality of our products and services through trained
  personnel, at competitive prices. Committed to achieving the satisfaction of our customers and
  interested parties, seeking continuous improvement of our quality management system and
  compliance with applicable requirements.</p>
      </div>
      <Footer />
    </div>
  </template>

  <script>
  import Navigator from "../components/Navigator";
  import Footer from "../components/Footer.vue";
  export default {
    name: "qualitypolicy",
    components: {
      Navigator,
      Footer,
    },
  };
  </script>


  <style>
      .logodis {
      background-image: url(../assets/logo-bnr.png) !important;
      }

      @media (min-width: 1024px) {
          .bloqueqp {
              padding-top: 15vw;
              padding-bottom: 15vw;
              width: 80%;
              margin: auto;
          }
          .title_qp {
              font-size: 3.6458333333333335vw;
              line-height: 4.635416666666667vw;
              text-align: left;
              margin-bottom: 5.53125vw;
              color: var(--color-2);
          }
          .title_qp span {
              color: var(--color-1);
          }
      }
      

  </style>